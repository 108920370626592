

export function toTitleCase(str, locale = 'en-US') {
  
  let i, j, lowers, uppers;
  
  if (['nl-NL'].includes(locale)) {
    lowers = ['De', 'Het', 'Der', 'Des', 'Een', 'En', 'Of', 'Van', 'Met', 'Maar', 'In', 'Bij', 'Voor'];
    
    uppers = ['Tv'];
  } else if (['en-US', 'en-GB'].includes(locale)) {
    lowers = ['A', 'An', 'The', 'And', 'But', 'Or', 'For', 'Nor', 'As', 'At', 
    'By', 'For', 'From', 'In', 'Into', 'Near', 'Of', 'On', 'Onto', 'To', 'With'];
    
    uppers = ['Id', 'Tv'];
  } else {
    lowers = [];
    uppers = [];
  }

  str = str.replace(/([^\W_]+[^\s-]*) */g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless 
  // they are the first or last words in the string
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp('\\s' + lowers[i] + '\\s', 'g'), 
      function(txt) {
        return txt.toLowerCase();
      });

  // Certain words such as initialisms or acronyms should be left uppercase
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp('\\b' + uppers[i] + '\\b', 'g'), 
      uppers[i].toUpperCase());

  return str;
}

export function formatName(str) {
  if (!str) return ''

  let i, j, lowers, uppers;
  
  lowers = ['De', 'Het', 'Der', 'Des'];
  uppers = [];

  str = str.replace(/[^a-z .-]/gi, '')

  str = str.replace(/([^\W_]+[^\s-]*) */g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless 
  // they are the first or last words in the string
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp('\\s' + lowers[i] + '\\s', 'g'), 
      function(txt) {
        return txt.toLowerCase();
      });

  // Certain words such as initialisms or acronyms should be left uppercase
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp('\\b' + uppers[i] + '\\b', 'g'), 
      uppers[i].toUpperCase());

  return str;
}

export function generateAttribution(holder, va = false) {
  let names = []

  if (!holder.Contributors) return ''
  
  holder.Contributors.forEach(c => {
    if (c.Role != 'Recording Artist') return

    let name = []
    if (c.NamePart1) {
      name.push(c.NamePart1)
    }
    
    if (c.NamePart2) {
      name.push(c.NamePart2)
    }
    
    if (c.NamePart3) {
      name.push(c.NamePart3)
    }

    names.push(name.join(' ').trim())
  })

  if (va && names.length > 3) {
    const trans = {
      'ar': 'فنانون متنوعون',
      'zh': '群星',
      'nl': 'Verschillende artiesten',
      'fi': 'Eri esittäjiä',
      'fr': 'Multi-interprètes',
      'de': 'Verschiedene Interpreten',
      'en': 'Various Artists',
      'el': 'Διάφοροι καλλιτέχνες',
      'he': 'אמנים שונים',
      'it': 'Artisti Vari',
      'pt': 'Vários intérpretes',
      'ru': 'Разные исполнители',
      'es': 'Varios Artistas',
      'sv': 'Blandade Artister',
      'th': 'รวมศิลปิน',
      'tr': 'Çeşitli Sanatçılar',
    }

    let lang = holder.TitleLanguage
    if (!lang.split)
      lang = 'en-GB'
    
    lang = lang.split('-')[0]
    
    if (!(lang in trans))
      lang = 'en'

    return trans[lang]
  }

  let c = names.length
  let out = ''
  while (c > 0) {
    out = [...names].splice(0, c).join(' / ')
    console.log(c)
    console.log(out.length)
    if (out.length <= 128) break

    c -= 1
  } 

  return out
}

export function barcodecheck(code) {
  const digits = () => /^\d{8,13}$/g.test(code);
  const validlengths = [8, 12, 13];
  if (!digits() || !validlengths.includes(code.length)) return false;

  let checksum = 0;
  const codelist = code.split("");
  const checkdigit = parseInt(codelist.pop(), 10);
  codelist.map((value, index) => {
    const digit = parseInt(value, 10);
    if (code.length % 2 === 1) checksum += index % 2 ? digit * 3 : digit;
    else checksum += index % 2 ? digit : digit * 3;
  });

  let check = checksum % 10;
  if (check !== 0) check = 10 - check;
  if (check === checkdigit) return true;
  return false;
}

export function checkIban(input) {
  let iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''),
      code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // split country / digits / accountnumber
      digits

  if (!code) {
      return false
  }

  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
      return letter.charCodeAt(0) - 55
  });

  // return check
  return mod97(digits) === 1
}

function mod97(string) {
  var checksum = string.slice(0, 2), fragment
  for (var offset = 2; offset < string.length; offset += 7) {
      fragment = String(checksum) + string.substring(offset, offset + 7)
      checksum = parseInt(fragment, 10) % 97
  }
  return checksum
}

export async function sleep(seconds) {
  return new Promise((resolve) =>setTimeout(resolve, seconds * 1000));
}

export function ucfirst(string) {
  return string.charAt(0).toUpperCase()
            + string.slice(1)
}